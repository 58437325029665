import { lazy, Suspense } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { userRoles } from "./constants"
import Smartlook from "smartlook-client"
import { useSelector } from "react-redux"
import { getToken, getUser, isSuperAdmin } from "./store/auth/auth.selectors"
import Layout from "./components/Layout"
import LoadingSpinner from "./components/common/Loading";
import AdminWrapper from "./admin-panel/AdminWrapper"

const Overview = lazy(() => import("./pages/Overview"))
const Settings = lazy(() => import("./pages/Settings"))
const Employees = lazy(() => import("./pages/Employees"))
const Leaderboard = lazy(() => import("./pages/Leaderboard"))
const AppPage = lazy(() => import("./pages/AppPage"))

// run payroll
const RunPayroll = lazy(() => import("./pages/RunPayroll"))

const RunPayrollPreview = lazy(() => import("./pages/RunPayrollPreview"))
const RunPayrollStatement = lazy(() => import("./pages/RunPayrollStatement"))
const RunPayrollProcess = lazy(() => import("./pages/RunPayrollProcess"))

const Expenses = lazy(() => import("./pages/Expenses"))
const ExpensesPreview = lazy(() => import("./pages/ExpensesPreview"))
const EmployeeStats = lazy(() => import("./pages/EmployeeStats"))
const EmployeeReport = lazy(() => import("./pages/EmployeeReport"))

// admin
const Insights = lazy(() => import("./admin-panel/pages/Insights"))
const Coupons = lazy(() => import("./admin-panel/pages/Coupons"))
const SuperAdminOrganizations = lazy(() => import("./admin-panel/pages/SuperAdminOrganizations"))
const PendingRequests = lazy(() => import("./admin-panel/pages/PendingRequests"))

// other
const OnboardCompanyInfo = lazy(() => import("./pages/OnboardCompanyInfo"))
const Login = lazy(() => import("./pages/Login"))
const SignUp = lazy(() => import("./pages/SignUp"))
const Reset = lazy(() => import("./pages/Reset"))
const SocialiteGoogle = lazy(() => import("./pages/SocialiteGoogle"))
const EmailVerify = lazy(() => import("./pages/EmailVerify"))
const Error404Page = lazy(() => import("./pages/Error404Page"))
const ServiceUnavailable = lazy(() => import("./pages/ServiceUnavailable"))

const routesArray = [
  {
    pathname: "app",
    component: AppPage
  },
  {
    pathname: "employees",
    component: Employees
  },
  {
    pathname: "employees/:employeeId",
    component: EmployeeStats
  },
  {
    pathname: "leaderboard",
    component: Leaderboard
  },
  {
    pathname: "/",
    component: Overview
  },
  {
    pathname: "run-payroll-preview/:id",
    component: RunPayrollPreview
  },
  {
    pathname: "run-payroll-preview/:payrollId/:statementId",
    component: RunPayrollStatement
  },
  {
    pathname: "run-payroll",
    component: RunPayroll
  },
  {
    pathname: "run-payroll/:payrollId/process",
    component: RunPayrollProcess
  },
  {
    pathname: "settings",
    component: Settings
  },
  {
    pathname: "expenses",
    component: Expenses
  },
  {
    pathname: "expenses-preview/:payrollId",
    component: ExpensesPreview
  }
]

const ProtectedRoute = ({ auth, redirectPath = "/login", children }) => {
  if (!auth) {
    return <Navigate to={redirectPath} />
  }

  return children ? children : <Outlet />
}

export default function Routing() {
  const token = useSelector(getToken)
  const curToken = localStorage.getItem("token") || token

  const isUserSuperAdminFromLocalStorage =
    JSON.parse(localStorage.getItem("user") || "{}")?.roles?.filter(
      (el) => el.name === userRoles.SUPER_ADMIN
    ).length > 0
  const isUserSuperAdmin = useSelector(isSuperAdmin)
  const curIsUserSuperAdmin = isUserSuperAdminFromLocalStorage || isUserSuperAdmin

  const isImpersonateUser = localStorage.getItem("impersonateUserToken")
  const loggedUser = useSelector(getUser)

  if (process.env.REACT_APP_SMARTLOOK_SITE_ID) {
    Smartlook.init(process.env.REACT_APP_SMARTLOOK_SITE_ID)

    Smartlook.record({
      forms: true,
      ips: true,
      numbers: true,
      emails: true,
      api: true
    })

    if (loggedUser && loggedUser.id) {
      Smartlook.identify(loggedUser.id, {
        user_email: loggedUser.email,
        organization_id: loggedUser.organization.id,
        organization_name: loggedUser.organization.name,
        is_subscribed: loggedUser.is_subscribed,
        is_impersonated: !!isImpersonateUser
      })
    }
  }

  return (
    <Routes>
      <Route
        path="503"
        element={
          <Suspense fallback={<LoadingSpinner/>}>
            <ServiceUnavailable />
          </Suspense>
        }
      />

      <Route element={<ProtectedRoute auth={!curToken} redirectPath="/" />}>
        <Route
          path="email/verify"
          element={
            <Suspense fallback={<LoadingSpinner/>}>
              <EmailVerify />
            </Suspense>
          }
        />
        <Route
          path="socialite/google/callback"
          element={
            <Suspense fallback={<LoadingSpinner/>}>
              <SocialiteGoogle />
            </Suspense>
          }
        />
        <Route
          path="login"
          element={
            <Suspense fallback={<LoadingSpinner/>}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="sign-up"
          element={
            <Suspense fallback={<LoadingSpinner/>}>
              <SignUp />
            </Suspense>
          }
        />
        <Route
          path="reset"
          element={
            <Suspense fallback={<LoadingSpinner/>}>
              <Reset />
            </Suspense>
          }
        />
      </Route>

      <Route
        path="*"
        element={
          <Suspense fallback={<LoadingSpinner/>}>
            <Error404Page />
          </Suspense>
        }
      />

      <Route element={<ProtectedRoute auth={curToken && curIsUserSuperAdmin} redirectPath="/" />}>
        <Route
          path="admin/organizations"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <AdminWrapper>
                <SuperAdminOrganizations />
              </AdminWrapper>
            </Suspense>
          }
        />
        <Route
          path="admin/pending-requests"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <AdminWrapper>
                <PendingRequests />
              </AdminWrapper>
            </Suspense>
          }
        />
        <Route
          path="admin/insights"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <AdminWrapper>
                <Insights />
              </AdminWrapper>
            </Suspense>
          }
        />

        <Route
          path="admin/coupons"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <AdminWrapper>
                <Coupons />
              </AdminWrapper>
            </Suspense>
          }
        />
      </Route>

      <Route
        path="employee-report/payroll/:payrollId/:employeeId"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <EmployeeReport />
          </Suspense>
        }
      />

      <Route element={<ProtectedRoute auth={curToken} />}>
        <Route
          path="onboard-company"
          element={
            <Suspense fallback={""}>
              <OnboardCompanyInfo />
            </Suspense>
          }
        />
        {routesArray.map((el, index) => {
          const Component = el.component
          return (
            <Route
              key={index}
              path={el.pathname}
              element={
                <Suspense fallback={""}>
                  <Layout
                    pathname={el.pathname}
                    isWageCalculatorPage={[
                      "run-payroll-preview/:id",
                      "run-payroll-preview/:payrollId/:statementId",
                      "run-payroll"
                    ].includes(el.pathname)}>
                    <Component />
                  </Layout>
                </Suspense>
              }
            />
          )
        })}
      </Route>
    </Routes>
  )
}
