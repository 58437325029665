import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  insightsList: [],
  insightsData: []
}

export const { actions, reducer } = createSlice({
  name: "insights",
  initialState,
  reducers: {
    setInsightsList: (state, action) => {
      state.insightsList = action.payload
    },
    setInsightsData: (state, action) => {
      state.insightsData = action.payload
    },
    resetInsightsData: (state) => {
      state.insightsList = []
      state.insightsData = []
    }
  }
})

export default reducer
