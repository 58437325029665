import { createSelector } from "@reduxjs/toolkit"
import { userRoles } from "../../constants"

export const getAuthData = (state) => state.auth || {}

export const getUser = createSelector(getAuthData, ({ user }) => user || {})
export const getToken = createSelector(getAuthData, ({ token }) => token || "")
export const isSuperAdmin = createSelector(
  getUser,
  ({ roles }) => roles?.filter((el) => el.name === userRoles.SUPER_ADMIN).length > 0
)
export const getIsDemoMode = createSelector(getAuthData, ({ isDemoMode }) => isDemoMode || false)
export const getCountUnreadNotifications = createSelector(
  getAuthData,
  ({ user }) => user?.count_unread_notifications || 0
)
