import HomeBig from "../assets/images/settings/home-big.png"
import ProfileBig from "../assets/images/settings/profile-big.png"
import BookBig from "../assets/images/settings/book.png"
import DollarBig from "../assets/images/settings/dollar-big.png"
import RankingBig from "../assets/images/settings/ranking-big.png"
import ExpensesIcon from "../assets/images/settings/expenses.png"
import AppBig from "../assets/images/settings/app-big.png"
import SettingsBig from "../assets/images/settings/settings-big.png"

import FedexLogo from "../assets/images/home/fedex-logo.png"
import AdpLogo from "../assets/images/home/adp-logo.png"
import GustoLogo from "../assets/images/home/gusto-logo.png"
import PaychexLogo from "../assets/images/home/paychex-logo.png"
import MailChimpLogo from "../assets/images/home/mailChimp-logo.png"
import QuickBooksLogo from "../assets/images/home/quickBooks-logo.png"

import CompaniesIconBig from "../assets/images/insights/companies-icon-big.png"

// export const LOCAL_STORAGE_KEYS = {
//   ACCESS_TOKEN: "access_token",
//   AUTHORIZATION_TOKEN: "authorization_token"
// }

export const analyticsItems = [
  {
    name: "Overview",
    icon: HomeBig,
    url: "/"
  },
  {
    name: "Leaderboard",
    icon: RankingBig,
    url: "/leaderboard"
  },
  {
    name: "Expenses",
    icon: ExpensesIcon,
    url: "/expenses"
  }
]

export const employeesItems = [
  {
    name: "Employees",
    icon: ProfileBig,
    url: "/employees"
  },
  {
    name: "Payroll",
    icon: DollarBig,
    url: "/run-payroll"
  }
]

export const supportItems = [
  {
    name: "Help Center",
    icon: BookBig,
    url: "http://fleetwage.com/help",
    redirect: true
  }
]

// export const organizationsItems = [
//   {
//     name: "Insights",
//     icon: ChartBig,
//     url: "/admin/insights"
//   },
//   {
//     name: "Pending Requests",
//     icon: <PendingActions sx={{ mr: "13px", width: "21px", height: "21px" }} />,
//     url: "/admin/pending-requests",
//     mui: true
//   },
//   {
//     name: "Organizations",
//     icon: AccountIcon2Big,
//     url: "/admin/organizations"
//   }
// ]

export const othersItems = [
  {
    name: "Integrations",
    icon: AppBig,
    url: "/app"
  },
  // {
  //   name: 'Training',
  //   icon: OtherChartBig,
  //   url: '/app'
  // },
  // {
  //   name: 'Handbook',
  //   icon: BookBig,
  //   url: '/app'
  // },
  {
    name: "Settings",
    icon: SettingsBig,
    url: "/settings"
  }
]

export const companyIcons = {
  fedex: FedexLogo,
  adp: AdpLogo,
  gusto: GustoLogo,
  paychex: PaychexLogo,
  mailChimp: MailChimpLogo,
  quickBooks: QuickBooksLogo
}

// export const totalBlocks = [
//   {
//     title: "Total Revenue",
//     subTitle: "$191,448",
//     diffIcon: ArrowUp,
//     chartIcon: RevenueChart,
//     percent: 1.3
//   },
//   {
//     title: "Total Stops",
//     subTitle: "59,751",
//     diffIcon: ArrowUp,
//     chartIcon: StopsChart,
//     percent: 2.1
//   },
//   {
//     title: "Profit Margin",
//     subTitle: "27.38%",
//     diffIcon: ArrowUp,
//     chartIcon: ProfitMarginChart,
//     percent: 0.7
//   }
// ]

// export const userList = [
//   {
//     id: 1,
//     image: UserBig,
//     firstName: "Dmitriy",
//     lastName: "Popello",
//     company: "Zev Express Inc",
//     station: "King of Prussia",
//     fedexId: 7953645,
//     employeeId: 10310,
//     payAdjustment: "Daily"
//   },
//   {
//     id: 2,
//     image: UserBig,
//     firstName: "Jeffrey",
//     lastName: "Yearick",
//     company: "Zev Express Inc",
//     station: "Prussia",
//     fedexId: 7101882,
//     employeeId: 10460,
//     payAdjustment: "Daily"
//   },
//   {
//     id: 3,
//     image: UserBig,
//     firstName: "Dmitriy",
//     lastName: "Popello",
//     company: "Zev Express Inc",
//     station: "King of Prussia",
//     fedexId: 7953645,
//     employeeId: 10310,
//     payAdjustment: "Weekly"
//   },
//   {
//     id: 4,
//     image: UserBig,
//     firstName: "Jeffrey",
//     lastName: "Yearick",
//     company: "Zev Express Inc",
//     station: "Prussia",
//     fedexId: 7101882,
//     employeeId: 10460,
//     payAdjustment: "Weekly"
//   },
//   {
//     id: 5,
//     image: UserBig,
//     firstName: "Jeffrey",
//     lastName: "Yearick",
//     company: "Zev Express Inc",
//     station: "Prussia",
//     fedexId: 7101882,
//     employeeId: 10460,
//     payAdjustment: "Daily"
//   },
//   {
//     id: 6,
//     image: UserBig,
//     firstName: "Dmitriy",
//     lastName: "Popello",
//     company: "Zev Express Inc",
//     station: "King of Prussia",
//     fedexId: 7953645,
//     employeeId: 10310,
//     payAdjustment: "Daily"
//   },
//   {
//     id: 7,
//     image: UserBig,
//     firstName: "Dmitriy",
//     lastName: "Popello",
//     company: "Zev Express Inc",
//     station: "King of Prussia",
//     fedexId: 7953645,
//     employeeId: 10310,
//     payAdjustment: "Daily"
//   },
//   {
//     id: 8,
//     image: UserBig,
//     firstName: "Jeffrey",
//     lastName: "Yearick",
//     company: "Zev Express Inc",
//     station: "Prussia",
//     fedexId: 7101882,
//     employeeId: 10460,
//     payAdjustment: "Weekly"
//   }
// ]

export const insightsTableArray = [
  {
    id: 1,
    name: "Zev Express Inc",
    stations: 2,
    revenue: "43,612.59",
    logo: CompaniesIconBig
  },
  {
    id: 2,
    name: "Urartu Logistics Inc",
    stations: 4,
    revenue: "60,200.35",
    logo: CompaniesIconBig
  },
  {
    id: 3,
    name: "Main Line Logistics Inc",
    stations: 3,
    revenue: "72,211.42",
    logo: CompaniesIconBig
  },
  {
    id: 4,
    name: "Next Mile Inc",
    stations: 1,
    revenue: "17,042.64",
    logo: CompaniesIconBig
  }
]

export const userRoles = {
  SUPER_ADMIN: "superadmin",
  ORGANIZATION_ADMIN: "organization_admin",
  MEMBER: "member"
}
