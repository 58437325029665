import { useEffect } from "react"
import { analyticsItems, employeesItems, othersItems, supportItems } from "../constants"
import LeftMenuHeader from "./common/LeftMenuHeader"
import LeftMenuBlock from "./left-menu/LeftMenuBlock"
import { useMediaQuery } from "@mui/material"
import { useLocation } from "react-router-dom"
import { ReactComponent as ArrowLeft } from "../assets/images/home/arrow-left.svg"
import { ReactComponent as ArrowRight } from "../assets/images/home/arrow-right.svg"
import { ReactComponent as FleetWageLogoEev } from "../assets/images/fleet-wage-logo-rev.svg"
import { isUserSourceRestricted } from "../helpers/helpers"
import { ReactComponent as PackageRouteWageLogoRev } from "../assets/images/package-route-logo-rev.svg"

const LeftMenu = ({ isCollapsed, setIsCollapsed, isOpen, setIsOpen }) => {
  const location = useLocation()

  const isMobile = useMediaQuery("(max-width:990px)")

  const isUserRestricted = isUserSourceRestricted()

  useEffect(() => {
    if (isMobile && isOpen) {
      setIsOpen(false)
    }
  }, [location])

  const handleOpenMenu = () => {
    localStorage.setItem("isMenuCollapsed", !isCollapsed)
    setIsCollapsed(!isCollapsed)
  }

  const filterMenuItems = (items) => {
    return items
      .filter((item) => {
        // Only include items that are not restricted or are restricted but the user is not restricted
        return !item.restricted || (item.restricted && !isUserRestricted)
      })
      .map((item) => {
        if (isUserRestricted) {
          if (item.name === "Settings") {
            return {
              ...item,
              name: "Custom Settings"
            }
          }
        }

        return item // Return the item as is if no modification is needed
      })
  }

  return (
    <div
      className="sidebar-sec scrollbar"
      style={{
        width: isCollapsed && !isMobile ? "100px" : isMobile ? "100%" : "290px",
        padding: isCollapsed ? "0 16px" : "0 24px"
      }}
    >
      {!isMobile && <LeftMenuHeader isCollapsed={isCollapsed} />}

      {isMobile ? (
        isOpen && (
          <div className="sidebar-menus-sec">
            <div style={{ width: "100%", margin: "0 0 12px 9px" }}>
              {isUserRestricted && (
                  <PackageRouteWageLogoRev style={{ marginLeft: "-1.5rem"}}/>
              )}
              {!isUserRestricted && (
                  <FleetWageLogoEev />
              )}
            </div>

            <div className="sidebar-top-sec">
              <LeftMenuBlock name="Analytics" itemArray={analyticsItems} />
              <LeftMenuBlock name="Employees" itemArray={employeesItems} />
            </div>

            <div style={{ width: "100%" }} className="sidebar-bottom-sec">
              {!isUserRestricted && (
                <div className="sidebar-bottom-sec" style={{ marginBottom: "25px" }}>
                  <LeftMenuBlock name="Support" itemArray={supportItems} />
                </div>
              )}
              <div className="sidebar-bottom-sec">
                <LeftMenuBlock name="Others" itemArray={filterMenuItems(othersItems)} />
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="sidebar-menus-sec">
          <div className="sidebar-top-sec">
            <LeftMenuBlock name="Analytics" itemArray={analyticsItems} isCollapsed={isCollapsed} />
            <LeftMenuBlock name="Employees" itemArray={employeesItems} isCollapsed={isCollapsed} />
          </div>

          <div style={{ width: "100%" }} className="sidebar-bottom-sec">
            {!isUserRestricted && (
              <div className="sidebar-bottom-sec" style={{ marginBottom: "25px" }}>
                <LeftMenuBlock name="Support" itemArray={supportItems} isCollapsed={isCollapsed} />
              </div>
            )}
            <div className="sidebar-bottom-sec">
              <LeftMenuBlock
                name="Others"
                itemArray={filterMenuItems(othersItems)}
                isCollapsed={isCollapsed}
              />
            </div>
          </div>

          <div className="sidebar-bottom-sec-collapse" onClick={handleOpenMenu}>
            {isCollapsed ? <ArrowRight /> : <ArrowLeft />}
          </div>
        </div>
      )}
    </div>
  )
}

export default LeftMenu
