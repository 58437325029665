import { createSelector } from "@reduxjs/toolkit"

export const getData = (state) => state.data || {}

export const getCompanies = createSelector(getData, ({ companies }) => companies || [])
export const getStations = createSelector(getData, ({ stations }) => stations || [])
export const getPositions = createSelector(getData, ({ positions }) => positions || [])
export const getCouponCode = createSelector(getData, ({ couponCode }) => couponCode || null)
export const getIsProcessPayrollStarted = createSelector(
  getData,
  ({ isProcessPayrollStarted }) => isProcessPayrollStarted || false
)
