import { configureStore } from "@reduxjs/toolkit"
import authReducer from "./auth/auth.reducers"
import dataReducer from "./data/data.reducers"
import payrollReducer from "./payroll/payroll.reducers"
import payrollPreviewReducer from "./payroll-preview/payroll-preview.reducers"
import employeeReducers from "./employee/employee.reducers"
import leaderboardReducers from "./leaderboard/leaderboard.reducers"
import insightsReducers from "./insights/insights.reducers"
import breadCrumbsReducers from "./bread-crumbs/bread-crumbs.reducers"
import organizationsReducers from "./organizations/organizations.reducers"
import pendingRequestsReducers from "./pending-requests/pending-requests.reducers"
import expensesReducers from "./expenses/expenses.reducers"

const store = configureStore({
  reducer: {
    auth: authReducer,
    data: dataReducer,
    employee: employeeReducers,
    payroll: payrollReducer,
    payrollPreview: payrollPreviewReducer,
    leaderboard: leaderboardReducers,
    insights: insightsReducers,
    breadCrumbs: breadCrumbsReducers,
    organizations: organizationsReducers,
    pendingRequests: pendingRequestsReducers,
    expenses: expensesReducers
  }
})

export default store
