import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  companies: [],
  stations: [],
  positions: [],
  couponCode: null,
  isProcessPayrollStarted: false
}

export const { actions, reducer } = createSlice({
  name: "data",
  initialState,
  reducers: {
    setIsProcessPayrollStarted: (state, action) => {
      localStorage.setItem("isProcessPayrollStarted", action.payload)
      state.isProcessPayrollStarted = action.payload
    },
    setCompanies: (state, action) => {
      state.companies = action.payload
    },
    setStations: (state, action) => {
      state.stations = action.payload
    },
    setPositions: (state, action) => {
      state.positions = action.payload
    },
    setCouponCode: (state, action) => {
      state.couponCode = action.payload
    },
    resetData: (state) => {
      state.companies = []
      state.stations = []
      state.positions = []
      state.couponCode = null
      state.isProcessPayrollStarted = false
    }
  }
})

export default reducer
