import React from "react"
import { useSelector } from "react-redux"
import { getIsDemoMode } from "./../../store/auth/auth.selectors"

const BlurText = ({ children }) => {
  const isDemoMode = useSelector(getIsDemoMode)

  if (!isDemoMode) {
    return children
  }

  return (
    <p style={{ color: "transparent", textShadow: "0 0 8px #000", display: "contents" }}>
      {children}
    </p>
  )
}

export default BlurText
