import React from "react"

const Toast = ({ title, text = null }) => {
  return (
    <div style={{ display: "flex" }}>
      <h3 style={{ fontSize: "15px", color: "#fff", marginBottom: 0 }}>{title}</h3>
      {text ? <p style={{ margin: "8px 0 0", fontSize: "14px", color: "#ccc" }}>{text}</p> : null}
    </div>
  )
}

export default Toast
