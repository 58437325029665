import LeftMenuItem from "./LeftMenuItem"

const LeftMenuBlock = ({ name, itemArray, isCollapsed }) => (
  <div className="sidebar-menus">
    <h6 style={{ padding: isCollapsed ? 0 : "0 0 8px 17px" }}>{name}</h6>

    <ul>
      {itemArray.map((item) => (
        <LeftMenuItem key={item.name} menuItem={item} isCollapsed={isCollapsed} />
      ))}
    </ul>
  </div>
)

export default LeftMenuBlock
