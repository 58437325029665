import { createSlice } from "@reduxjs/toolkit"
import { formatMoney } from "../../utils"
import { calculateTotalPayAndPerStop } from "./../../utils"

const initialState = {
  payrollTotalStats: [],
  payrollStatements: [],
  statementTotalStats: [],
  statementEmployees: [],
  statementAllData: {},
  payrollData: {}
}

export const { actions, reducer } = createSlice({
  name: "payrollPreview",
  initialState,
  reducers: {
    setPayrollTotalStats: (state, action) => {
      state.payrollTotalStats = action.payload
    },
    setStatementAllData: (state, action) => {
      state.statementAllData = action.payload
    },
    setPayrollStatements: (state, action) => {
      state.payrollStatements = action.payload
    },
    setPayrollData: (state, action) => {
      state.payrollData = action.payload
    },
    setStatementTotalStats: (state, action) => {
      state.statementTotalStats = action.payload
    },
    deleteOneFromEmployeeTotalNumber: (state) => {
      const newStatementTotalStats = state.statementTotalStats.map((el) => {
        switch (el.name) {
          case "Employees":
            return {
              ...el,
              count: +el.count - 1,
            }
          default:
            return el
        }
      })
      state.statementTotalStats = newStatementTotalStats
    },
    changeStatementEmployeeTotalPay: (state, action) => {
      const changeElement = state.statementEmployees.map((el) => {
        if (el.id === action.payload.id) {
          return {
            ...el,
            totalPay: action.payload.totalPay,
            total_fuel: action.payload.totalFuel,
            addition: action.payload.bonus,
            deduction: action.payload.deduction,
            pto: action.payload.newPto,
            training_days: action.payload.newTrainingDays,
            total_hours: action.payload.totalHours,
            perStop: action.payload.perStop,
            profit: action.payload.profit
          }
        }

        return el
      })

      const newTotalW2 = changeElement.reduce((acc, el) => {
        const statementData = {
          station_id: action.payload.station_id,
          station: { csa: el.employee.station?.csa }
        }

        const { elTotalPay: totalPayroll } = calculateTotalPayAndPerStop(
          el,
          statementData.station_id
        )

        if (typeof acc === "number") {
          return acc + totalPayroll
        }

        return acc
      }, 0)

      const newTotalFuel = changeElement.reduce((acc, el) => {
        if (acc) {
          return acc + +el.total_fuel
        }

        return +el.total_fuel
      }, 0)

      const total_charges = state.statementTotalStats.find((el) => el.name === "Revenue").number
      const total_expenses = state.statementTotalStats.find((el) => el.name === "Expenses").number

      const newStatementTotalStats = state.statementTotalStats.map((el) => {
        switch (el.name) {
          case "W-2":
            return {
              ...el,
              count: formatMoney(newTotalW2),
              percentage: ((total_charges ? newTotalW2 / total_charges : 0) * 100).toFixed(2) || 0
            }
          case "Fuel":
            return {
              ...el,
              count: formatMoney(newTotalFuel),
              percentage: ((total_charges ? newTotalFuel / total_charges : 0) * 100).toFixed(2) || 0
            }
          case "Profit": {
            const newProfit = +total_charges - +newTotalW2 - +total_expenses - +newTotalFuel
            return {
              ...el,
              count: formatMoney(newProfit),
              percentage: ((total_charges ? +newProfit / total_charges : 0) * 100).toFixed(2) || 0
            }
          }
          case "Employees": {
            const newCount = changeElement.reduce((acc, el) => {
              if (el.totalPay > 0 || el.working_days + el.training_days + el.pto > 0) {
                return acc + 1
              }

              return acc
            }, 0)
            return { ...el, count: newCount || 0 }
          }
          default:
            return el
        }
      })

      state.statementEmployees = changeElement
      state.statementTotalStats = newStatementTotalStats
    },
    setStatementEmployees: (state, action) => {
      state.statementEmployees = action.payload
    },
    addAlertToExpenses: (state) => {
      const newStatementTotalStats = state.statementTotalStats.map((el) => {
        if (el.name === "Expenses") {
          return { ...el, alert: "This value is recalculated after the page is refreshed!" }
        }

        return el
      })

      state.statementTotalStats = newStatementTotalStats
    },
    resetPayroll: (state) => {
      state.payrollTotalStats = []
      state.payrollStatements = []
      state.statementTotalStats = []
      state.statementEmployees = []
      state.payrollData = {}
      state.statementAllData = {}
    }
  }
})

export default reducer
