import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  breadCrumbs: []
}

export const { actions, reducer } = createSlice({
  name: "bread-crumbs",
  initialState,
  reducers: {
    setBreadCrumbs: (state, action) => {
      state.breadCrumbs = action.payload
    },
    resetBreadCrumbs: (state) => {
      state.breadCrumbs = []
    }
  }
})

export default reducer
