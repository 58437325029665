import { useCallback, useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import { useLocation } from "react-router"
import TopNav from "./components/TopNav"
import SideNav from "./components/SideNav"

const SIDE_NAV_WIDTH = 280

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH
  }
}))

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
  padding: "0 24px"
})

export const AdminLayout = (props) => {
  const { children } = props
  const { pathname } = useLocation()
  const [openNav, setOpenNav] = useState(false)

  const handlePathnameChange = useCallback(() => {
    if (openNav) {
      setOpenNav(false)
    }
  }, [openNav])

  useEffect(() => {
    handlePathnameChange()
  }, [pathname])

  return (
    <>
      <TopNav onNavOpen={() => setOpenNav(true)} />
      <SideNav onClose={() => setOpenNav(false)} open={openNav} />
      <LayoutRoot>
        <LayoutContainer>{children}</LayoutContainer>
      </LayoutRoot>
    </>
  )
}
