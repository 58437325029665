export function formatMoney(value = 0, withCents = false) {
  if (!value) value = 0
  const res =  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: withCents || value === 0 ? 2 : 0,
    minimumFractionDigits: withCents || value === 0 ? 2 : 0
  }).format(value)

  return String(res)
}

export function formatNumber(value = 0) {
  return String(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
}

export function calculateTotalPayAndPerStop(weeklySummary, stationId, newEmployee, totalFuel) {
  const {
    addition = 0,
    deduction = 0,
    pto = 0,
    employee,
    total_hours,
    total_w2 = 0,
    total_stops = 0,
    total_fuel = 0,
    training_days = 0,
    is_hours_disabled,
    total_wa_hourly
  } = weeklySummary
  const curEmployee = newEmployee || employee || {}
  const curTotalFuel = totalFuel || total_fuel || {}
  const { rates } = curEmployee
  const isWeekly = Boolean(rates?.weekly_rate_w2) && rates?.adding_daily_pay === 0
  const { paid_time_off = 0, hourly_rate = 0, training_rate = 0 } = rates || {}
  const isOvertime = curEmployee?.rates?.is_overtime_enabled && +total_hours > 40
  const calculatedHour = rates?.subtracting_daily_pay
    ? 0
    : is_hours_disabled === 1
      ? total_wa_hourly
      : isOvertime
        ? 40 * rates.hourly_rate + (+total_hours - 40) * (1.5 * rates.hourly_rate)
        : total_hours * hourly_rate

  const isNotNativeCompany = stationId !== curEmployee.station?.csa
  const trainingDaysPay = +training_days * +training_rate

  const elTotalPay =
    !isWeekly && !isNotNativeCompany
      ? total_w2 + +addition - +deduction + pto * paid_time_off + calculatedHour + trainingDaysPay
      : total_w2 +
        +addition -
        +deduction +
        calculatedHour +
        (!isWeekly && isNotNativeCompany ? trainingDaysPay : 0)
  const perStop =
    +total_stops > 0 && +elTotalPay > 0 ? (+elTotalPay + +curTotalFuel) / +total_stops : 0

  return { elTotalPay, perStop }
}

export function calculateProfit(totalW2, data, source) {
  let total_fuel = 0;

  if (source === "payroll") {
    total_fuel = data.file_total_fuel === 0 ? 0 : +data.total_fuel + +data.missed_fuel_diff;
  }

  if (source === "statement") {
    total_fuel = +data.total_fuel;
  }

  const profit = +data.total_charges - +totalW2 - +data.total_expenses - +total_fuel

  return profit
}
