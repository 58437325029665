import {
  PendingActions,
  Apartment,
  InsertChartOutlinedTwoTone,
  Discount
} from "@mui/icons-material"

export const items = [
  {
    title: "Organizations",
    path: "/admin/organizations",
    icon: <Apartment sx={{ width: "21px", height: "21px" }} />
  },
  {
    title: "Pending Requests",
    path: "/admin/pending-requests",
    icon: <PendingActions sx={{ width: "21px", height: "21px" }} />
  },
  {
    title: "Insights",
    path: "/admin/insights",
    icon: <InsertChartOutlinedTwoTone sx={{ width: "21px", height: "21px" }} />
  },
  {
    title: "Coupons",
    path: "/admin/coupons",
    icon: <Discount sx={{ width: "21px", height: "21px" }} />
  }
]
